<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ข้อมูลหัวข้ออะคาเดมี่
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3" v-if="$isLoading">
        <b-row class="pl-1">
          <b-col cols="6">
            <div class="panel d-flex align-items-md-center">
              <!-- <b-form-checkbox size="lg" id="highlight"
                >เป็นไฮไลท์</b-form-checkbox
              > -->
              <b-form-checkbox
                size="lg"
                id="sameLang"
                class="mt-0 mt-sm-3"
                @change="useSameLanguage"
                v-model="form.isSameLanguage"
                >ใช้เหมือนกันทุกภาษา
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="6" class="text-right">
            <div class="panel">
              <b-button
                type="button"
                class="btn btn-language"
                v-for="(language, index) in languageList"
                v-bind:key="index"
                v-bind:class="[languageActive == language.id ? 'active' : '']"
                @click="changeLanguage(language.id, index)"
                :disabled="form.isSameLanguage ? true : false"
              >
                <span class="text-uppercase">{{ language.nation }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="6">
            <div
              v-for="(item, index) in form.translationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <InputText
                  textFloat="ชื่อหัวข้ออะคาเดมี่"
                  placeholder="ชื่อหัวข้ออะคาเดมี่"
                  type="text"
                  name="name"
                  v-model="item.name"
                  isRequired
                  :isValidate=" $v.form.translationList.$each.$iter[index].name.$error"
                  :v="$v.form.translationList.$each.$iter[index].name"
                  :img="imageLogoLang"
                  @onKeyup="setUrlKey(item.name, index)"
                  @input="setUrlKey(item.name, index)"
                />
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <InputText
              textFloat="ลำดับการแสดงผล"
              placeholder="ลำดับการแสดงผล"
              type="text"
              name="sortorder"
              @onKeypress="isNumber($event)"
              v-model="form.sortOrder"
              isRequired
              :isValidate=" $v.form.sortOrder.$error"
              :v="$v.form.sortOrder"
            />
          </b-col>
          
        </b-row>

        <b-row>
          <b-col md="6">
            <InputText
              textFloat="URL Key"
              placeholder="URL Key"
              type="text"
              name="urlKey"
              v-model="form.urlKey"
              isRequired
              :isValidate=" $v.form.urlKey.$error"
              :v="$v.form.urlKey"
              @onKeyup="onUrlkeyChange"
            />
          </b-col>
          <b-col md="6">
            <div
              v-for="(item, index) in form.translationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <InputText
                  textFloat="แท็กของหัวข้อ"
                  placeholder="แท็กของหัวข้อ"
                  type="text"
                  name="tag"
                  :img="imageLogoLang"
                  v-model="item.tag"
                  @onKeyup="setTagByLang(item.tag, index)"
                  isRequired
                  :isValidate="$v.form.translationList.$each.$iter[index].tag.$error"
                  :v="$v.form.translationList.$each.$iter[index].tag"
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพไอคอน"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="thumbnail"
              :fileName="fileNameIcon"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 1:1 ขนาดไม่เกิน 50 MB"
              isRequired
              v-on:onFileChange="onIconChange"
              v-on:delete="deleteIcon"
              :v="$v.fileNameIcon"
            />
            <div
              class="preview-box ratio-1-1-pb"
              v-if="iconCoverImgType == 1"
              v-bind:style="{
                'background-image': 'url(' + iconShowPreview + ')',
              }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingIconImage"
              />
            </div>
          </b-col>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพแบนเนอร์สำหรับคอมพิวเตอร์"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="thumbnail"
              :fileName="fileNameComputer"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 10:2 ขนาดไม่เกิน 50 MB"
              isRequired
              v-on:onFileChange="onImageChange"
              v-on:delete="deleteImage"
              :v="$v.fileNameComputer"
            />
            <div
              class="preview-box ratio-10-2-pb"
              v-if="coverImgType == 1"
              v-bind:style="{ 'background-image': 'url(' + showPreview + ')' }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage"
              />
            </div>
          </b-col>
          
        </b-row>

        <b-row>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพแบนเนอร์สำหรับมือถือ"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="thumbnail"
              :fileName="fileNameMobile"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 10:4 ขนาดไม่เกิน 50 MB"
              isRequired
              v-on:onFileChange="onMobileImageChange"
              v-on:delete="deleteMobileImage"
              :v="$v.fileNameMobile"
            />
            <div
              class="preview-box ratio-10-4-pb"
              v-if="mobileCoverImgType == 1"
              v-bind:style="{
                'background-image': 'url(' + mobileShowPreview + ')',
              }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingMobileImage"
              />
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <label class="font-weight-bold main-label">
              สถานะการแสดงผล
              <span class="text-danger">*</span>
            </label>
            <div>
              <b-form-checkbox
                switch
                v-model="form.display"
                class="radio-active"
                size="lg"
              >
                <span class="ml-2 main-label">{{
                  form.display ? "ใช้งาน" : "ไม่ใช้งาน"
                }}</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col md="6">
            <b-button
              v-if="isEdit"
              class="btn btn-danger btn-details-set mr-md-2"
              :disabled="isDisable"
              @click="openModalDelete(form.translationList[0].name)"
              >ลบ</b-button
            >
            <b-button
              href="/academy-topic"
              :disabled="isDisable"
              class="btn-details-set btn-cancel"
              >ย้อนกลับ</b-button
            >
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="checkForm(0)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
            <button
              type="button"
              @click="checkForm(1)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึกและออก
            </button>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-container class="container-box mt-3" v-if="id > 0">
      <div class="bg-white p-3" v-if="$isLoading">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold header-main text-uppercase mb-3">
              รายการเนื้อหา
            </h1>
          </b-col>
        </b-row>
        <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(imageUrl)="data">
                <div class="position-relative">
                  <div
                    class="square-box b-contain"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                  ></div>
                </div>
              </template>
              <template v-slot:cell(sortOrder)="data">
                <div v-if="data.item.sortOrder == 0">-</div>
                <div v-else>{{ data.item.sortOrder }}</div>
              </template>
              <template v-slot:cell(updatedTime)="data">
                <span>{{
                  new Date(data.item.updatedTime) | moment($formatDate)
                }}</span>
              </template>
              <template v-slot:cell(display)="data">
                <div v-if="data.item.display == true" class="text-success">
                  ใช้งาน
                </div>
                <div v-else class="text-danger">ไม่ใช้งาน</div>
              </template>
              <template v-slot:cell(id)="data">
                <div class="d-flex justify-content-center">
                  <router-link :to="'/academy-list/details/' + data.item.id">
                    <b-button variant="link" class="text-dark px-1 py-0">
                      ดูรายละเอียด
                    </b-button>
                  </router-link>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
      </div>
    </b-container>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import { required, numeric } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import Vue from "vue";

export default {
  name: "AcademyTopicDetails",
  components: {
    InputText,
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoadingImage: false,
      isLoadingMobileImage: false,
      isLoadingIconImage: false,
      coverImgType: 1,
      showPreview: "",
      mobileCoverImgType: 1,
      mobileShowPreview: "",
      iconCoverImgType: 1,
      iconShowPreview: "",
      isEdit: false,
      images: "",
      mobileImages: "",
      modalMessage: "",
      isDisable: false,
      fileNameComputer: "",
      fileNameMobile: "",
      fileNameIcon: "",
      languageList: [],
      languageActive: 1,
      imageLogoLang: "",
      form: {
        academyArticle: [],
        deleted: false,
        display: false,
        icon: "",
        id: 0,
        imageUrl: "",
        isSameLanguage: false,
        mainLanguageId: 1,
        mobileImageUrl: "",
        sortOrder: 0,
        urlKey: "",
        translationList: [
          {
            name: "",
            languageId: 1,
            tag: null,
          },
          {
            name: "",
            languageId: 2,
            tag: null,
          },
        ],
      },
      imgTypeId: 26,
      fields: [
        {
          key: "imageUrl",
          label: "ภาพปก",
          class: "w-100px",
        },
        {
          key: "name",
          label: "ชื่อ",
          class: "w-200",
          tdClass: "text-left",
        },
        {
          key: "sortOrder",
          label: "ลำดับการแสดง",
          class: "w-100px",
        },
        {
          key: "academy",
          label: "หัวข้อที่แสดงผล",
          class: "w-200",
          tdClass: "text-left",
        },
        {
          key: "updatedTime",
          label: "อัพเดทล่าสุด",
          class: "w-100px",
        },
        {
          key: "display",
          label: "สถานะการแสดง",
          class: "w-100px",
        },
        {
          key: "id",
          label: "",
          class: "w-100px",
        },
      ],
      isBusy: false,
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
        Status: [],
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" }
      ],
    };
  },
  validations: {
    fileNameComputer: { required },
    fileNameMobile: { required },
    fileNameIcon: { required },
    form: {
      urlKey: { required },
      sortOrder: { numeric },
      translationList: {
        $each: {
          tag: { required },
          name: { required },
        },
      },
    },
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeSameLang: function (value) {
      this.form.isSameLanguage = value;
    },
    setTagByLang: function (value, index) {
      this.form.translationList[index].tag = value.replace(/ /g, "-");
    },
    getAcademyArticle: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/academy/academyArticle/list/${this.id}`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;

        this.isBusy = false;
        this.$isLoading = true;
      }
    },
    getDatas: async function () {
      this.$isLoading = false;

      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
        this.changeLanguage(1, 0);
      }
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/academy/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail.academy;

        //this.$v.form.$reset();

        if (this.form.id > 0) {
          this.isEdit = true;
          this.showPreview = this.form.imageUrl;
          this.fileNameComputer = this.form.imageUrl;
          this.mobileShowPreview = this.form.mobileImageUrl;
          this.fileNameMobile = this.form.mobileImageUrl;
          this.iconShowPreview = this.form.icon;
          this.fileNameIcon = this.form.icon;
          await this.getAcademyArticle();
        }else{
          this.form.isSameLanguage = true;
          this.form.mainLanguageId = 1;
        }
      }

      if (this.form.isSameLanguage) {
        this.imageLogoLang = "";
      } else {
        var index = this.languageList
          .map(function (x) {
            return x.id;
          })
          .indexOf(this.languageActive);
        this.imageLogoLang = this.languageList[index].imageUrl;
      }

      this.$isLoading = true;
    },
    changeLanguage(id, index) {
      this.languageActive = id;
      this.imageLogoLang = this.languageList[index].imageUrl;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;

        this.showPreview = this.images;
        this.form.imageUrl = this.images;
        this.fileNameComputer = this.images;

        if (img.type == "video/mp4") {
          this.coverImgType = 2;
          var vid = this.$refs.videoRef;
          if (vid != undefined) {
            vid.load();
          }
        } else {
          this.coverImgType = 1;
        }
      };
    },
    onIconChange(img) {
      this.isLoadingIconImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingIconImage = false;
        this.isDisable = false;

        this.iconShowPreview = this.images;
        this.form.icon = this.images;
        this.fileNameIcon = this.images;

        if (img.type == "video/mp4") {
          this.iconCoverImgType = 2;
          var vid = this.$refs.videoRef;
          if (vid != undefined) {
            vid.load();
          }
        } else {
          this.iconCoverImgType = 1;
        }
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage() {
      this.form.imageUrl = null;
      this.fileNameComputer = "";
      this.showPreview = null;

      if (this.coverImgType == 2) {
        var vid = this.$refs.videoRef;
        vid.load();
        this.coverImgType = 1;
      }
    },
    deleteIcon() {
      this.form.icon = null;
      this.fileNameIcon = "";
      this.iconShowPreview = null;

      if (this.coverImgType == 2) {
        var vid = this.$refs.videoRef;
        vid.load();
        this.iconCoverImgType = 1;
      }
    },
    onMobileImageChange(img) {
      this.isLoadingMobileImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.mobileImages = await this.saveImagetoDb(reader.result);
        this.isLoadingMobileImage = false;
        this.isDisable = false;

        this.mobileShowPreview = this.mobileImages;
        this.form.mobileImageUrl = this.mobileImages;
        this.fileNameMobile = this.mobileImages;

        if (img.type == "video/mp4") {
          this.mobileCoverImgType = 2;
          var vid = this.$refs.videoMobileRef;
          if (vid != undefined) {
            vid.load();
          }
        } else {
          this.mobileCoverImgType = 1;
        }
      };
    },
    deleteMobileImage() {
      this.form.mobileImageUrl = null;
      this.fileNameMobile = "";
      this.mobileShowPreview = null;

      if (this.mobileCoverImgType == 2) {
        var vid = this.$refs.videoMobileRef;
        vid.load();
        this.mobileCoverImgType = 1;
      }
    },
    checkForm: async function (flag) {
      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      }
      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidateTranslationList();
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/academy/save`,
        null,
        this.$headers,
        { academy: this.form }
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.existId = data.detail;
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        if (this.flag == 1) {
          setTimeout(() => {
            this.$router.push({
              path: `/academy-topic`,
            });
          }, 3000);
        } else {
          setTimeout(() => {
            this.$refs.modalAlert.hide();
          }, 3000);
          if (this.id > 0) {
            this.getDatas();
          } else {
            this.form.id = this.existId;
            this.id = this.existId;
            this.isEdit = true;
            this.$router.push({ path: `/academy-topic/details/${this.existId}` });
          }
        }
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.mainLanguageId = this.languageActive;
          let data = this.form.translationList.filter(
            (val) => val.languageId == this.form.mainLanguageId
          );

          //if (this.id == 0) {
          if (data.length == 1) {
            data = data[0];
            for (
              let index = 0;
              index < this.form.translationList.length;
              index++
            ) {
              this.form.translationList[index].name = data.name;
              this.form.translationList[index].tag = data.tag;
            }
          }
          //}
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.translationList.filter(
            (val) => val.languageId != this.form.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.name = "";
              data.tag = "";
            }
          }
        }
      });
    },
    checkValidateTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.translationList.$each.$iter[index].$error) {
            this.languageActive = this.$v.form.translationList.$model[
              index
            ].languageId;

            this.imageLogoLang = this.languageList[index].imageUrl;

            isError = true;
          }
        }
      });
    },
    btnDelete: async function () {
      this.$refs.isModalAlertConfirm.hide();

      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/academy/delete/${this.id}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/academy-topic`,
          });
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    openModalDelete(name) {
      this.modalMessage = "คุณต้องการลบ " + name + " ใช่หรือไม่?";
      this.$refs.isModalAlertConfirm.show();
    },
    pagination(Page) {
      window.scrollTo(0, 0);
      this.filter.PageNo = Page;
      this.getAcademyArticle();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getAcademyArticle();
    },
    setUrlKey: function (name, index) {
      this.form.urlKey = name.replace(/ /g, "-").replace(/[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/g, "");
    },
    onUrlkeyChange: function (value) {
      this.form.urlKey = this.form.urlKey.replace(/ /g, "-").replace(/[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/g, "");
    },
  },
};
</script>

<style scoped>
.panel {
  font-size: 17px;
  color: #16274a;
}
</style>
